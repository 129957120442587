import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  firstInit: true,
  showMenu: false,
  showLogin: false,
  showEditInfo: false,
  showVideo: false,
  userInfo: {
    id: "",
    user_name: "",
    avatar: "",
  },
};

const getters = {
  getFirstInit: state => state.firstInit,
  getShowMenu: state => state.showMenu,
  getShowLogin: state => state.showLogin,
  getShowEditInfo: state => state.showEditInfo,
  getUserInfo: state => state.userInfo,
  getShowVideo: state => state.showVideo,
};

const mutations = {
  mutation_firstInit(state, firstInit) {
    state.firstInit = firstInit;
    console.log('SET_firstInit', firstInit);
  },
  mutation_showMenu(state, showMenu) {
    state.showMenu = showMenu;
    console.log('SET_showMenu', showMenu);
  },
  mutation_showLogin(state, showLogin) {
    state.showLogin = showLogin;
    console.log('SET_showLogin', showLogin);
  },
  mutation_showEditInfo(state, showEditInfo) {
    state.showEditInfo = showEditInfo;
    console.log('SET_showEditInfo', showEditInfo);
  },
  mutation_userInfo(state, userInfo) {
    state.userInfo = userInfo;
    console.log('SET_userInfo', userInfo);
  },
  mutation_showVideo(state, showVideo) {
    state.showVideo = showVideo;
    console.log('SET_showVideo', showVideo);
  },
};


const actions = {
  // action_role({ commit }, newVal) {
  //   console.log('context---', newVal);
  //   commit('mutation_role', newVal);
  // },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
})